import { createInput } from '../core/sdk/baseInput';
import { CAR_NAMEPLATE_FIELDS, JOURNEY, NAMEPLATEBODYSTYLE, PRICES, BASE_PRICE } from '../core/sdk/constants/sdkFields';
import { carListProvider } from '../core/sdk/providers';
import { addEmployeeTypeFilter, addFilters, addWithoutOptionsFilter } from '../core/sdk/sdk.service';
import { CASH } from '../filters/filters';
import { ICarListParameters } from './carList.types';
import { ASC_PRICE } from './car';

export const getCarNameplatesInput = ({ paymentJourneyType, filters, sort }: ICarListParameters) => {
    const input = createInput(carListProvider);
    input.addLevelAggregation(NAMEPLATEBODYSTYLE, null, NAMEPLATEBODYSTYLE);

    addFilters(filters, input);
    addEmployeeTypeFilter(input, `${PRICES}.${BASE_PRICE}`);
    if (paymentJourneyType === CASH) {
        addEmployeeTypeFilter(input);
    } else {
        addEmployeeTypeFilter(input);
    }
    addWithoutOptionsFilter(input);

    const RELEVANCY_AGREGATION_1 = paymentJourneyType === CASH ? 'prices.basePrice' : 'prices.monthlyPrices.amount';
    const RELEVANCY_AGREGATION_2 = {
        size: 1,
        sort: paymentJourneyType === CASH ? sort : ASC_PRICE,
    };

    input
        .addRelevancyAggregation(
            RELEVANCY_AGREGATION_1,
            RELEVANCY_AGREGATION_2,
            NAMEPLATEBODYSTYLE,
            CAR_NAMEPLATE_FIELDS
        )
        .addExtra(JOURNEY, paymentJourneyType);

    return input;
};
