import React from 'react';
import { BRAND, isMarketGB } from '../../../constants/main';
import { OffersListStyledAC } from './AC/OffersListStyled.AC';
import { OffersListStyledAP } from './AP/OffersListStyled.AP';
import { OffersListStyledDS } from './DS/OffersListStyled.DS';
import { OffersListStyledOV } from './OV/OffersListStyled.OV';
import { OffersListStyledOVGB } from './OV/GB/OffersListStyled.OV';
import { OffersListStyled } from './OffersListStyled';

const OffersList = () => {
    if (BRAND === 'AC') return <OffersListStyledAC />;
    if (BRAND === 'OV') return isMarketGB ? <OffersListStyledOVGB /> : <OffersListStyledOV />;
    if (BRAND === 'AP') return <OffersListStyledAP />;
    if (BRAND === 'DS') return <OffersListStyledDS />;
    return <OffersListStyled />;
};

export default OffersList;
