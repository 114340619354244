import React, { useEffect, FC, memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from '@components/Carousel';
import Main from '@components/Layout/Main';
import { HandledComponentError } from '@components/ErrorHandling';
import { ContentDuck } from '../../../redux/content/content.duck';
import FAQs from '@components/FAQs';
import OffersList from '../../OffersList/Configurable';
import { useLoadingReset } from '@hooks/useLoadingReset';
import { useTranslations } from '@hooks/useTranslations';
import { useMyAccountRedirect } from '@hooks/useMyAccountRedirect';
import { useScrollToPositionOnMount } from '@hooks/useScrollToPositionOnMount';
import { useGTM } from '@hooks/useGTM';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { FILTER_CATEGORY, IS_B2B, ModalVersion } from '../../../constants/main';
import { Redux } from 'src/redux/redux.interface';
import CarDetailsDuck from '../../../redux/carDetails/carDetails.duck';
import ConfiguratorDuck from '../../../redux/configurator/configurator.duck';
import OfferListDuck from '../../../redux/carList/offerlist.duck';
import LoaderOverlay from '../../LoaderOverlay';
import { GtmData } from '../../../types/gtm';
import SidebarFilterBox from '@components/SidebarFilterBox';
import useOnClickOutside from '@hooks/useOnClickOutside';
import FilterBox from '@components/FilterBox';
import { useRouter } from 'next/router';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { DEAL_ERROR_TYPES } from '../../../constants/enums';

const ConfigurableHomepage: FC = memo(() => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTranslations();
    const { pushHomePageLoad, pushToDataLayer } = useGTM();
    const { handleScrollTo } = useScrollToPositionOnMount();
    const { isStockJourney } = useCarStockJourneyCheck();

    const banners = useSelector((state: Redux) => ContentDuck.getConfigurableBanners(state));
    const carouselAutoPlay = useSelector((state: Redux) => ContentDuck.getDynamicBannersSetting(state));
    const filteredOffers = useSelector((state) => OfferListDuck.getFilteredOffers(state));

    const fullBrand = t('title');

    const filterRef = useRef();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);

    useEffect(() => {
        if (router.query.expiredAt && router.query.errorType === DEAL_ERROR_TYPES.EXPIRED) {
            dispatch(
                UIDuck.openModal({
                    data: {
                        rrdi7: router.query.rrdi7,
                        expiredAt: router.query.expiredAt,
                    },
                    modalType: UIDuck.MODAL_TYPES.OFFER_EXPIRED,
                    modalVersion: ModalVersion.v2,
                    modalProperties: {
                        centerModeOnDesktop: true,
                    },
                })
            );
        }
    }, []);

    useEffect(() => {
        pushHomePageLoad(false);
        pushToDataLayer({
            eventCategory: 'SolRetail::VehicleList',
            eventAction: 'VehicleModelsView::NonInteractive',
            eventLabel: 'Vehicle models view',
        } as GtmData);
        dispatch(CarDetailsDuck.resetCarDetails());
        dispatch(ConfiguratorDuck.resetConfigurator());
        dispatch(UIDuck.closeModal(UIDuck.MODAL_TYPES.LOGOUT));
    }, []);

    useEffect(() => handleScrollTo(), [handleScrollTo]);
    useLoadingReset();
    useMyAccountRedirect();

    const { pushProductImpressions } = useGTM();
    useEffect(() => {
        pushProductImpressions('home', filteredOffers);
    }, [filteredOffers]);

    const homePageCanonicalLink = `/configurable`;

    useOnClickOutside(filterRef, () => setIsFilterOpen(false), []);

    const openFilterByCategory = (category: FILTER_CATEGORY) => {
        setSelectedCategoryName(category);
        setIsFilterOpen(true);
    };

    try {
        return (
            <>
                <Main
                    title={IS_B2B ? t('seo.homePage.b2b.title') : t('seo.homePage.title')}
                    description={
                        IS_B2B
                            ? t('seo.homePage.b2b.description', { siteName: fullBrand })
                            : t('seo.homePage.description', { siteName: fullBrand })
                    }
                    canonicalLink={homePageCanonicalLink}
                    hasOpenFilter={isFilterOpen}
                >
                    <div ref={filterRef}>
                        <SidebarFilterBox
                            selectedCategoryName={selectedCategoryName}
                            isOpen={isFilterOpen}
                            closeFilter={() => setIsFilterOpen(false)}
                        />
                    </div>
                    <Carousel banners={banners} autoPlay={carouselAutoPlay} />
                    <FilterBox openFilter={(category: FILTER_CATEGORY) => openFilterByCategory(category)} />
                    {!isStockJourney ? <OffersList /> : <LoaderOverlay loading />}
                    <FAQs />
                </Main>
            </>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});
ConfigurableHomepage.displayName = 'ConfigurableHomepage';
export default ConfigurableHomepage;
