import styled from 'styled-components';
import { OffersListStyled } from '../OffersListStyled';
import { rem } from 'polished';

export const OffersListStyledDS = styled(OffersListStyled)`
    .offer-list-title {
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        font-size: ${rem(20)};
    }

    .frontpage-legaltext-over {
        margin-left: 0;
        color: ${({ theme }) => theme.colors.black};
    }
`;
