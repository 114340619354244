import { NextPage } from 'next';
import React from 'react';
import ConfigurableHomepage from '@components/Homepage/Configurable';
import { IsHomepageContext } from '../contexts/IsHomepageContext';
import { FeatureSwitchesContext } from '../types/featureSwitches';
import { ContextWithStore } from '@utils/getInitialProps/@types';
import { handleFilters, handleSorting, updateGeneralContext } from '@utils/getInitialProps/common';
import { handleInitialURLParams } from '@utils/page.utils';
import { IOfferItem } from '../interfaces/IOfferItem';
import CarListService from '../services/carList/carlist.service';
import OfferListDuck from '../redux/carList/offerlist.duck';
import { batch } from 'react-redux';
import router from 'next/router';
import { SSRError } from '@components/SSRError';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import FilterDuck from 'src/redux/filters/filter.duck';
import UIDuck from 'src/redux/commonDucks/ui.duck';

interface IHomePage {
    statusCode: number;
}

const HomePage: NextPage<IHomePage> = ({ statusCode }) => {
    if (!statusCode || statusCode > 404) return <SSRError />;

    return (
        <IsHomepageContext.Provider value={true}>
            <ConfigurableHomepage />
        </IsHomepageContext.Provider>
    );
};

const DESERIALIZATION_ERROR = 'DeSerializationError';
HomePage.getInitialProps = async (ctx: FeatureSwitchesContext<ContextWithStore>) => {
    let statusCode = undefined;
    const { store, query, res, asPath, featureContext } = await updateGeneralContext(ctx, {
        headers: [
            {
                key: 'Cache-Control',
                value: `private, no-cache`,
            },
        ],
    });

    try {
        handleInitialURLParams(store, asPath, res, router, featureContext);

        const sorting = handleSorting(store, query);

        const defaultPaymentJourney = featureContext[FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY];

        // #region ALL CARS REQUEST
        let allCars: IOfferItem[] = [];
        let response = await CarListService.getCarList([], defaultPaymentJourney, sorting);

        if (response) {
            allCars = response.data;
            statusCode = response.statusCode;
        } else {
            statusCode = 503;
        }
        // #end region ALL CARS REQUEST

        const filters = await handleFilters(store, query, defaultPaymentJourney);

        if (filters && filters?.exportedFiltersWithPrice?.length > 0) {
            // #region FILTERED CARS REQUEST
            let filteredCars: IOfferItem[] = [];
            response = await CarListService.getCarList(
                filters.exportedFiltersWithPrice,
                defaultPaymentJourney,
                sorting
            );

            if (response) {
                filteredCars = response.data;
                statusCode = response.statusCode;
            } else {
                statusCode = 503;
            }
            // #end region FILTERED CARS REQUEST

            store.dispatch(OfferListDuck.setFilteredOffers(filteredCars));
        } else {
            store.dispatch(OfferListDuck.setFilteredOffers(allCars));
        }

        batch(() => {
            store.dispatch(UIDuck.closeModal(UIDuck.MODAL_TYPES.TRIM_COMPARE));
            store.dispatch(OfferListDuck.setOffers(allCars));
            store.dispatch(
                FilterDuck.changeBudgetType(featureContext[FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY])
            );
        });

        return { statusCode, namespacesRequired: ['common'] };
    } catch (error: any) {
        if (error?.name === DESERIALIZATION_ERROR) {
            store.dispatch(OfferListDuck.setFilteredOffers([]));
        }
        console.error(new Date(), error);

        return { statusCode: 503, namespacesRequired: ['common'] };
    }
};

export default HomePage;
