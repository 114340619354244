import React, {FC, useMemo, useState, useCallback, memo, useRef} from 'react';
import { useSelector } from 'react-redux';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { Col, Grid, Row } from 'src/components/Grid';
import OfferGrid from 'src/components/OfferGrid/OfferGridStyled';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useTranslations } from '@hooks/useTranslations';
import { useJourneyType } from '@hooks/useJourneyType';
import { BRAND } from 'src/constants/main';
import RetailPerformance from '@utils/retailPerformance';
import {
    MEASURE_FIRST_POD_LOADED_HP_CASH,
    MEASURE_FIRST_POD_LOADED_HP_FINANCE,
} from '@utils/retailPerformance/constants';
import OfferListDuck from 'src/redux/carList/offerlist.duck';
import { OffersList } from './OffersList';
import { HandledComponentError } from '@components/ErrorHandling';
import PriceLegalText from '@components/PriceLegalText';
import { useGetNotEscapedTranslationMarkup } from '@hooks/useGetNotEscapedTranslationMarkup';
import { useTranslationKeyProvided } from '@hooks/useContentDuck';
import { sortOffersDescByPriority } from '@utils/Offers.utils';

interface OffersListTemplateProps {
    className?: string;
}

const OffersListTemplate: FC<OffersListTemplateProps> = memo(({ className }) => {
    const { t } = useTranslations();
    const { isFinance, isCash } = useJourneyType();
    const filteredOffers = useSelector((state) => OfferListDuck.getFilteredOffers(state));
    const filteredOutOffers = useSelector((state) => OfferListDuck.getFilteredOutOffers(state));
    let title = isCash ? 'OfferListTemplate.title.cash' : 'OfferListTemplate.title.finance';
    title = useTranslationKeyProvided(title) ? t(title) : '';
    const [firstPodLoaded, setFirstPodLoaded] = useState(false);
    const { getNotEscapedTranslationMarkup } = useGetNotEscapedTranslationMarkup();
    const isLoading = useSelector((state) => UIDuck.isLoading(state));

    const { isStockJourney } = useCarStockJourneyCheck();

    const filteredOffersWithIndex = useMemo(
        () => filteredOffers.map((offer, index) => ({ ...offer, index })),
        [filteredOffers]
    );

    const filteredOutOffersWithIndex = useMemo(
        () => filteredOutOffers.map((offer, index) => ({ ...offer, index: index + filteredOffersWithIndex.length })),
        [filteredOutOffers, filteredOffersWithIndex]
    );

    const handlePodLoaded = useCallback(() => {
        if (!firstPodLoaded) {
            setFirstPodLoaded(true);

            RetailPerformance.createMeasure(
                isFinance ? MEASURE_FIRST_POD_LOADED_HP_FINANCE : MEASURE_FIRST_POD_LOADED_HP_CASH,
                'navigationStart'
            );
        }
    }, [firstPodLoaded, isFinance]);

    try {
        return (
            <OfferGrid className={className} brand={BRAND}>
                <Grid noPaddingMobile supressMediumBpMaxWidth style={{ width: '100%' }}>
                    {!filteredOutOffersWithIndex.length && !filteredOffers.length ? (
                        <Row id="skip-to-content">
                            <Col
                                xs={12}
                                style={{
                                    height: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <h4 style={{ marginBottom: '30px' }}>No offers found</h4>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <h2 className="offer-list-title">{title}</h2>
                            <Row id="skip-to-content" style={{ alignItems: 'stretch' }} noNegativeMarginMobile>
                                <OffersList
                                    list={[
                                        ...filteredOffersWithIndex,
                                        ...filteredOutOffersWithIndex.map((item) => ({ ...item, isDisabled: true })),
                                    ].sort(sortOffersDescByPriority)}
                                    isDisabled={isLoading}
                                    onPodLoaded={handlePodLoaded}
                                />
                            </Row>
                        </>
                    )}
                    <PriceLegalText className="frontpage-legaltext-over">
                        <p className="frontpage-legaltext">
                            <span dangerouslySetInnerHTML={getNotEscapedTranslationMarkup('filters.legal.text')} />
                        </p>
                    </PriceLegalText>
                </Grid>
            </OfferGrid>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
});
OffersListTemplate.displayName = 'OffersListTemplate';
export default OffersListTemplate;
