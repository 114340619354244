import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import OfferListItemTemplate from './OfferListTemplate';
import { rem } from 'polished';

export const OffersListStyled = styled(OfferListItemTemplate)`
    .offer-list-title {
        text-align: center;
        font-size: ${rem('32px')};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-weight: ${({ theme }) => theme.fontWeights.textLight};
        line-height: unset;
        text-transform: uppercase;
        margin-top: ${rem('25px')};
        margin-bottom: ${rem('25px')};

        @media (max-width: ${breakpoints.xs}px) {
            margin-top: ${rem('15px')};
            margin-bottom: ${rem('15px')};
            font-size: ${rem('18px')};
            margin-left: ${rem('5px')};
            margin-right: ${rem('5px')};
        }
    }
`;
