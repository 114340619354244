import { TBudgetType } from '../../interfaces/IFilters';
import { SORT_TYPE } from '../core/sdk/constants/sdkFields';
import { carListProvider } from '../core/sdk/providers';
import { CASH, EMPLOYEE_PRICE_TYPE } from '../filters/filters';
import { getCarNameplatesInput } from './carInput';
import { ICarListParameters, IFilter } from './carList.types';

/*
 * service example
 */
class CarListService {
    /**
     * Format response and return only required data
     * @param serverResponse
     */
    private static formatCarListResponse = (serverResponse: any) => {
        const carList = serverResponse._data._items._items;
        return {
            statusCode: serverResponse._statusCode,
            items: carList,
        };
    };

    /**
     * Create configurable input.
     * This function is exported only for test case. Do not use it.
     * @internal
     * @param parameters
     */
    public static createCarListInput = (parameters: ICarListParameters) => {
        return getCarNameplatesInput(parameters);
    };

    /**
     * Get List of cars from server
     * @returns Promise
     */
    public static getCarList = async (filters: IFilter[], paymentJourneyType: TBudgetType, sort: SORT_TYPE) => {
        const parameters: ICarListParameters = { paymentJourneyType, filters, sort };
        try {
            const input = getCarNameplatesInput(parameters);
            const response = await carListProvider.getInputResults(input);

            let data;
            if (paymentJourneyType === CASH) {
                data = CarListService.formatCarListResponse(response).items;
            } else {
                data = CarListService.formatCarListResponse(response).items.filter((item: any) => {
                    const price = item.prices.find((price: any) => price.type === EMPLOYEE_PRICE_TYPE);
                    return price && price.monthlyPrices.length;
                });
            }

            return { data, statusCode: response?._statusCode };
        } catch (e: any) {
            console.log('EE__', e);

            return { data: [], statusCode: e?.statusCode };
        }
    };
}

export default CarListService;
