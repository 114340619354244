import styled from 'styled-components';
import { OffersListStyled } from '../OffersListStyled';
import { rem } from 'polished';

export const OffersListStyledAC = styled(OffersListStyled)`
    .offer-list-title {
        font-size: ${rem(22)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        color: ${({ theme }) => theme.colors.grey5};
    }
`;
