import styled from 'styled-components';
import { OffersListStyled } from '../../OffersListStyled';
import { rem } from 'polished';

export const OffersListStyledOVGB = styled(OffersListStyled)`
    .offer-list-title {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${rem('28px')};
    }
`;
